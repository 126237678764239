
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ResearchesInMonth from "./widgets/ResearchesInMonth.vue";

interface Subject {
  _id: string;
  name: string;
}

interface Research {
  _id: string;
  title: string;
  createdAt: string;
  subject: Subject;
  levels: string[];
  module: string;
}

export default defineComponent({
  name: "advisor-researches",
  components: { ResearchesInMonth },

  setup() {
    const { t } = useI18n();
    const store = useStore();

    const researches = ref<Research[]>([]);
    const filteredResearches = ref<Research[]>([]);

    const search = ref<string>("");
    const selectedLevel = ref<string>("");

    const selectedSubject = ref<string>("");
    const loading = ref(true);

    const subjects = computed(() =>
      store.getters.currentUser.subjects.filter((s) => s.status !== "inactive")
    );
    const levels = computed(() => store.getters.currentUser.levels);

    ApiService.get("/lms/advisor/research")
      .then(({ data }) => {
        data.forEach((research) => {
          researches.value.push(research);
        });
        filteredResearches.value = [...researches.value];
      })
      .catch((e) => console.log(e))
      .finally(() => {
        loading.value = false;
      });

    const getLevelResearches = (id: string) => {
      return researches.value.filter((research: Research) =>
        research.levels.includes(id)
      ).length;
    };

    const filterResearches = () => {
      currentPage.value = 1;
      filteredResearches.value = researches.value.filter((research) => {
        const toSearch = search.value.toLowerCase();
        return (
          research.title.toLowerCase().includes(toSearch) &&
          (selectedLevel.value == "" ||
            research.levels.find((level) => level == selectedLevel.value)) &&
          (research.subject._id == selectedSubject.value ||
            selectedSubject.value == "")
        );
      });
    };

    onMounted(() => {
      setCurrentPageTitle(t("course.myResearches"));
    });

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredResearches.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredResearches.value.slice(startIndex - 1, endIndex);
    });

    return {
      t,
      researches,
      search,
      moment,
      filterResearches,
      filteredResearches,
      selectedLevel,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      subjects,
      selectedSubject,
      loading,
      levels,
      getLevelResearches,
    };
  },
});
