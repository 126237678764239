import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-xl-stretch mb-5 mb-xl-8" }
const _hoisted_2 = { class: "card-body d-flex flex-column p-0" }
const _hoisted_3 = { class: "card-p" }
const _hoisted_4 = { class: "me-2" }
const _hoisted_5 = {
  href: "#",
  class: "text-dark text-hover-primary fw-bolder fs-3"
}
const _hoisted_6 = { class: "text-muted fw-bold mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.title), 1)
          ]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("course.addedResearchesInMonth", {
              nb: _ctx.currentMonthResearches.length,
            })), 1)
        ])
      ]),
      _createVNode(_component_apexchart, {
        class: "statistics-widget-3-chart card-rounded-bottom",
        options: _ctx.chartOptions,
        series: _ctx.series,
        height: _ctx.height,
        type: "area"
      }, null, 8, ["options", "series", "height"])
    ])
  ]))
}